var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded && _vm.asset
    ? _c(
        "div",
        {
          class: [
            _vm.sidebar ? "sidebar-open" : null,
            _vm.centerContent() ? "centercontent" : null,
          ],
          attrs: { id: "app" },
        },
        [
          _c("Header"),
          _c("router-view", { key: _vm.$route.fullPath, staticClass: "main" }),
          _c("Footer"),
        ],
        1
      )
    : _vm.error
    ? _c("Error", { attrs: { error: _vm.error } })
    : _c("Loading")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }