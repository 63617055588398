<template>
	<header :class="headerClasses()">
		<b-container>
			<b-row align-v="center" :class="smallHeader() ? 'justify-content-center' : null">
				<b-col cols="12" :md="smallHeader() && useMenu() ? '4' : null" class="d-none d-md-block" v-if="useMenu()"></b-col>
				<b-col cols="12" :md="smallHeader() && useMenu() ? '4' : null" :class="smallHeader() ? 'text-center' : null" v-if="channel && channel.logos && channel.logos.length">
					<img :src="getLogo()" />
				</b-col>
				<b-col cols="12" :md="useMenu() ? '4' : null" class="text-center text-md-right" v-if="useMenu()">
					<a 	href="#"
						class="toggle"
						:class="[channel.skin_header_text ? channel.skin_header_text : 'dark', showMenu ? 'visible' : null]"
						@click.prevent="showMenu = !showMenu">
						<span></span>
						<span></span>
						<span></span>
					</a>
					<ul class="menu" :class="[channel.skin_background_header ? channel.skin_background_header : 'light', showMenu ? 'visible' : null]">
						<li v-if="showQuestion()">
							<a 	href="#"
								@click.prevent="$scrollTo('#askQuestion'), showMenu = false"
								class="small"
								:title="$t('common.ask-question')">
								{{ $t('common.ask-question') }}
							</a>
						</li>
						<li v-if="showChat()">
							<a 	href="#"
								@click.prevent="sidebar === 'chat' ? hideSidebar() : showSidebar('chat'), showMenu = false"
								class="small"
								:title="$t('common.chat')">
								{{ sidebar === 'chat' ? $t('common.hide-chat') : $t('common.chat') }}
							</a>
						</li>
						<li v-if="showPoll()">
							<a 	href="#"
								@click.prevent="sidebar === 'poll' ? hideSidebar() : showSidebar('poll'), showMenu = false"
								class="small"
								:title="$t('common.poll')">
								{{ sidebar === 'poll' ? $t('common.hide-poll') : $t('common.poll') }}
							</a>
						</li>
						<li v-if="showQueButtons()">
							<a 	href="#"
								@click.prevent="$bvModal.show('addQuestionModal'), showMenu = false"
								class="small"
								:title="$t('questions.add-question')">
								{{ $t('questions.add-question') }}
							</a>
						</li>
						<li v-if="showQueButtons() && questions && questions.moderator && questions.moderator.length">
							<download-excel :data="questions.moderator"
											:fields="exportFields"
											type="xls"
											:name="genFilename(asset.title) + '.xls'">
								<a 	href="#"
									@click.prevent="null, showMenu = false"
									class="small"
									:title="$t('questions.export-data')">
									{{ $t('questions.export-data') }}
								</a>
							</download-excel>
						</li>
					</ul>
				</b-col>
			</b-row>
		</b-container>
	</header>
</template>
<script>
	export default {
		computed: {
			asset() {
				return this.$store.getters.asset
			},
			category() {
				return this.$store.getters.category
			},
			channel() {
				return this.$store.getters.channel
			},
			questionPassword() {
				if(this.$store.getters.questionPassword) return this.$store.getters.questionPassword
				if(sessionStorage.getItem('questionPassword')) return sessionStorage.getItem('questionPassword')
				return null
			},
			questionType() {
				return this.$store.getters.questionType
			},
			questions() {
				return this.$store.getters.questions
			},
			stream() {
				if(this.asset && this.asset.stream_select && !this.$store.getters.stream) return true
				return false
			},
			sidebar() {
				return this.$store.getters.sidebar
			},
			prelive() {
				if(this.asset && this.asset.broadcasting !== null && this.asset.broadcasting === 0) return 'prelive'
				return false
			},
			live() {
				if(this.asset && this.asset.broadcasting !== null && (this.asset.broadcasting === 1 || this.$route.name === 'preview')) return true
				return false
			},
			calendar() {
				if(this.asset && this.asset.is_calendar_reminder && this.asset.calender_url && ['asset', 'preview'].includes(this.$route.name)) return true
				return false
			},
			question() {
				if(this.asset && this.asset.asset_qa && this.asset.asset_qa.enabled && ['asset', 'preview'].includes(this.$route.name)) return true
				return false
			},
			chat() {
				if(this.asset && this.asset.chat_url && ['asset', 'preview'].includes(this.$route.name)) return true
				return false
			},
			poll() {
				if(this.asset && this.asset.poll_url && ['asset', 'preview'].includes(this.$route.name)) return true
				return false
			}
		},
		data() {
			return {
				showMenu: false,
				exportFields: {
					"Full name": "name",
					"Title": "title",
					"Company": "company",
					"Phone": "phone",
					"E-mail": "email",
					"Question": "text",
					"Created": "created_at",
					"Updated": "updated_at"
				}
			}
		},
		methods: {
			getLogo() {
				return this.channel.logos_path + this.channel.logos[0].filename
			},
			showQuestion() {
				if(this.live && this.question && !this.stream) return true
				return false
			},
			showChat() {
				if(this.live && this.chat && !this.stream) return true
				return false
			},
			showPoll() {
				if(this.live && this.poll && !this.stream) return true
				return false
			},
			showQueButtons() {
				if(this.$route.name === 'questions' && this.questionPassword && this.questionType && this.questionType === 'moderator') return true
				return false
			},
			genFilename(title) {
				title = title.replace(/ /g, '_')
				const moment = this.$moment
				const now = moment().format('YYYY-MM-DD_hh_mm_ss')
				title = title + '_' + now
				return title
			},
			showSidebar(val) {
				this.$store.dispatch('sidebar', val)
			},
			hideSidebar() {
				this.$store.dispatch('sidebar', null)
			},
			useMenu() {
				if(['asset', 'preview', 'questions'].includes(this.$route.name) && this.asset && this.showQuestion()) return true
				if(['asset', 'preview', 'questions'].includes(this.$route.name) && this.asset && this.showChat()) return true
				if(['asset', 'preview', 'questions'].includes(this.$route.name) && this.asset && this.showPoll()) return true
				if(['asset', 'preview', 'questions'].includes(this.$route.name) && this.asset && this.showQueButtons()) return true
				return false
			},
			headerClasses() {
				return [
					this.channel && this.channel.skin_background_header ? 'bg-'+this.channel.skin_background_header : 'bg-light',
					this.channel && this.channel.skin_header_text ? 'text-'+this.channel.skin_header_text : 'text-dark',
					this.smallHeader() ? 'small-header' : null
				]
			},
			smallHeader() {
				if(this.live && this.$route.name !== 'questions' || this.$route.name === 'questions' && this.questionType) return true
				return false
			}
		}
	}
</script>
