var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { class: _vm.headerClasses() },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            {
              class: _vm.smallHeader() ? "justify-content-center" : null,
              attrs: { "align-v": "center" },
            },
            [
              _vm.useMenu()
                ? _c("b-col", {
                    staticClass: "d-none d-md-block",
                    attrs: {
                      cols: "12",
                      md: _vm.smallHeader() && _vm.useMenu() ? "4" : null,
                    },
                  })
                : _vm._e(),
              _vm.channel && _vm.channel.logos && _vm.channel.logos.length
                ? _c(
                    "b-col",
                    {
                      class: _vm.smallHeader() ? "text-center" : null,
                      attrs: {
                        cols: "12",
                        md: _vm.smallHeader() && _vm.useMenu() ? "4" : null,
                      },
                    },
                    [_c("img", { attrs: { src: _vm.getLogo() } })]
                  )
                : _vm._e(),
              _vm.useMenu()
                ? _c(
                    "b-col",
                    {
                      staticClass: "text-center text-md-right",
                      attrs: { cols: "12", md: _vm.useMenu() ? "4" : null },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "toggle",
                          class: [
                            _vm.channel.skin_header_text
                              ? _vm.channel.skin_header_text
                              : "dark",
                            _vm.showMenu ? "visible" : null,
                          ],
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.showMenu = !_vm.showMenu
                            },
                          },
                        },
                        [_c("span"), _c("span"), _c("span")]
                      ),
                      _c(
                        "ul",
                        {
                          staticClass: "menu",
                          class: [
                            _vm.channel.skin_background_header
                              ? _vm.channel.skin_background_header
                              : "light",
                            _vm.showMenu ? "visible" : null,
                          ],
                        },
                        [
                          _vm.showQuestion()
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "small",
                                    attrs: {
                                      href: "#",
                                      title: _vm.$t("common.ask-question"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        _vm.$scrollTo("#askQuestion"),
                                          (_vm.showMenu = false)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.$t("common.ask-question")) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.showChat()
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "small",
                                    attrs: {
                                      href: "#",
                                      title: _vm.$t("common.chat"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        _vm.sidebar === "chat"
                                          ? _vm.hideSidebar()
                                          : _vm.showSidebar("chat"),
                                          (_vm.showMenu = false)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.sidebar === "chat"
                                            ? _vm.$t("common.hide-chat")
                                            : _vm.$t("common.chat")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.showPoll()
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "small",
                                    attrs: {
                                      href: "#",
                                      title: _vm.$t("common.poll"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        _vm.sidebar === "poll"
                                          ? _vm.hideSidebar()
                                          : _vm.showSidebar("poll"),
                                          (_vm.showMenu = false)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.sidebar === "poll"
                                            ? _vm.$t("common.hide-poll")
                                            : _vm.$t("common.poll")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.showQueButtons()
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "small",
                                    attrs: {
                                      href: "#",
                                      title: _vm.$t("questions.add-question"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        _vm.$bvModal.show("addQuestionModal"),
                                          (_vm.showMenu = false)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("questions.add-question")
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm.showQueButtons() &&
                          _vm.questions &&
                          _vm.questions.moderator &&
                          _vm.questions.moderator.length
                            ? _c(
                                "li",
                                [
                                  _c(
                                    "download-excel",
                                    {
                                      attrs: {
                                        data: _vm.questions.moderator,
                                        fields: _vm.exportFields,
                                        type: "xls",
                                        name:
                                          _vm.genFilename(_vm.asset.title) +
                                          ".xls",
                                      },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "small",
                                          attrs: {
                                            href: "#",
                                            title: _vm.$t(
                                              "questions.export-data"
                                            ),
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              null, (_vm.showMenu = false)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("questions.export-data")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }