var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("footer", [
      _c("div", { staticClass: "powered" }, [
        _c("img", {
          staticClass: "img-fluid",
          attrs: { src: require("@/assets/img/power.svg") },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }